import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {getUniqueString} from '@Utils/string.util';
import {LoadingFailedPanel} from '@Components/lazy-panel/components/loading-failed-panel';
import LoadingPanel from '@Panels/loading-panel/loading-panel';
import useDefaultPanelClose from '@Panels/hooks/useDefaultPanelClose';
import {initLazyPanel, setLazyPanelHasFailedToLoad, setLazyPanelIsLoading} from './lazy-panel-reducer';
import {useAppDispatch, useAppSelector} from '@/hooks';

export interface LazyPanelProps {
  lazyPanelId?: string;
  showCloseButtonOnLoadError?: boolean;
  panelDirectoryName: string;
  panelProps: any;
}

export function LazyPanel({showCloseButtonOnLoadError = true, ...props}: LazyPanelProps): ReactElement {
  const lazyPanelId = useRef(props.lazyPanelId ?? getUniqueString());
  const [panelModule, setPanelModule] = useState<any>(null);
  const hasFailedToLoad = useAppSelector((state) => {
    return state.lazyPanels.lazyPanelHashmap[lazyPanelId.current]?.hasFailedToLoad ?? false;
  });
  const dispatch = useAppDispatch();
  const onClose = useDefaultPanelClose(props.panelProps.panelId, props.panelProps.hideOnClose);

  useEffect(() => {
    dispatch(
      initLazyPanel({
        lazyPanelId: lazyPanelId.current,
        isLoading: true,
      })
    );

    void loadPanel();
  }, []);

  useEffect(() => {
    const onOnline = (): void => {
      if (hasFailedToLoad) {
        retryPanelLoad();
      }
    };
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('online', onOnline);
    };
  }, [hasFailedToLoad]);

  const loadPanel = async (): Promise<void> => {
    dispatch(setLazyPanelIsLoading({lazyPanelId: lazyPanelId.current, isLoading: true}));
    try {
      const splitName = props.panelDirectoryName.split('/');
      let module: ReactElement | null = null;
      if (splitName.length === 1) {
        module = await import(`../panels/${splitName[0]}/index.ts`);
      } else if (splitName.length === 2) {
        module = await import(`../panels/${splitName[0]}/${splitName[1]}/index.ts`);
      } else {
        throw new Error(`Unhandled panelDirectoryname: ${props.panelDirectoryName}`);
      }
      // const module = await import(`../panels/${props.panelDirectoryName}/index.ts`);
      setPanelModule(module);
      dispatch(setLazyPanelIsLoading({lazyPanelId: lazyPanelId.current, isLoading: false}));
    } catch (e) {
      dispatch(
        setLazyPanelHasFailedToLoad({
          lazyPanelId: lazyPanelId.current,
          hasFailedToLoad: true,
        })
      );
      console.error(e);
    }
  };

  const retryPanelLoad = (): void => {
    dispatch(
      setLazyPanelHasFailedToLoad({
        lazyPanelId: lazyPanelId.current,
        hasFailedToLoad: false,
      })
    );
    void loadPanel();
  };

  const onLoadingFailedPanelClose = (): void => {
    onClose();
    props.panelProps?.onClose();
  };

  const getErrorPanel = (): ReactElement => {
    return <LoadingFailedPanel showCloseButton={showCloseButtonOnLoadError} onRefresh={retryPanelLoad} onClose={onLoadingFailedPanelClose} />;
  };

  if (hasFailedToLoad) {
    return getErrorPanel();
  }

  if (!panelModule) {
    return <LoadingPanel />;
  }

  return <panelModule.default {...props.panelProps} />;
}

import type {MouseEventHandler} from 'react';
import React from 'react';
import {Panel, PanelContent, PanelHeader} from '@Components/panel';
import {Button, Size as BUTTON_SIZE, Type as BUTTON_TYPE} from '@Components/button';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';

interface LoadingFailedPanelProps {
  onClose?: MouseEventHandler;
  showCloseButton?: boolean;
  onRefresh: MouseEventHandler;
}

export function LoadingFailedPanel({onClose = $.noop, showCloseButton = false, ...props}: LoadingFailedPanelProps) {
  const getContent = () => {
    return (
      <PanelContent className="flex-center flex-v-row">
        <Icon shape={IconShape.CIRCLE} type={IconType.FLAT} icon="icon-offline" className="spacing-m-b-4" size={IconSize.SIZE_ICON_24} />
        <Text className="spacing-m-b-3" size={TextSize.XSMALL} val={window.i18next.t('pmwjs_internet_error_message_panel')} />
        <Button type={BUTTON_TYPE.SECONDARY} icon="icon-refresh" size={BUTTON_SIZE.SMALL} text={window.i18next.t('pmwjs_refresh')} onClick={props.onRefresh} />
      </PanelContent>
    );
  };

  return <Panel panelHeader={showCloseButton ? <PanelHeader onClose={onClose} /> : null} panelContent={getContent()} />;
}
